.Modal__Information {
	padding: 50px 20px 20px 20px;
	text-align: center;
	img {
		display: block;
		margin: 20px auto 50px;
	}
	p {
		font-size: 28px;
		line-height: 40px;
		b {
			color: #38518F;
		}
		&.small {
			font-size: 22px;
			line-height: 26px;
			font-weight: 300;
		}
		a {
			color: #585858;
			text-decoration: none;
		}
	}
	&-block {
		background: rgba(187, 187, 187, 0.07);
		border-radius: 5px;
		padding: 20px 35px;
		color: #585858;
		font-family: Roboto;
		.company {
			p {
				b {
					color: #585858;
				}
			}
		}
	}
	@media only screen and (max-width: 768px) {
		padding: 20px 0;
		img {
			display: block;
			margin: 0px auto 20px;
			width: 30px;
		}
		p {
			font-size: 10px;
			line-height: 16px;
			&.small {
				font-size: 10px;
				line-height: 12px;
			}
		}
	}
	&-block {
		padding: 10px 10px;
		.company {
			p {
				b {
					color: #585858;
				}
			}
		}
	}
}
