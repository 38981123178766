.Modal__Instruction {
	padding: 50px 20px 20px 20px;
	img.ico-instr {
		display: block;
		margin: 20px auto 50px;
	}
	p {
		font-size: 24px;
		line-height: 28px;
	}
	.images {

	}
	.table {
		margin: 20px 0;
		text-align: center;
		table, th, td {
			border: 1px solid rgb(187 187 187 / 14%);
		}
		table {
			border-collapse: collapse;
			font-size: 18px;
			line-height: 22px;
			tr {
				th {
					font-weight: 400;
					padding: 5px;
				}
				td {
					padding: 5px;
				}
				&:nth-child(even) {
					td {
						background-color: rgb(187 187 187 / 14%);
					}
				}
			}
		}
		&.align {
			&--left {
				text-align: left;
				table {
					tr {
						th {
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
	&.big_instruction {
		font-weight: 200;
		p {
			margin: 8px 0;
			font-size: 18px;
			line-height: 22px;
		}
		b {
			font-weight: 400;
		}
	}
	@media only screen and (max-width: 768px) {
		padding: 20px 0;
		img.ico-instr {
			display: block;
			margin: 0px auto 20px;
			width: 23px;
		}
		p {
			font-size: 10px;
			line-height: 12px;
		}
		.table {
			overflow: auto;
			table {
				font-size: 10px;
				line-height: 12px;
			}
		}
	}
}
