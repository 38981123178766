.Main {
	min-height: 100%;
  	display: flex;
  	flex-direction: column;
  	background-color: #38518F;
	.center__block {
		flex: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		&_image {
			height: 30vh;
		    display: flex;
		    justify-content: center;
		    align-items: flex-end;
			img {
				width: 100%;
				max-width: 726px;
			}
		}
		&_title {
  			font-family: 'Lato', sans-serif;
			font-size: 70px;
			color: #fff;
			font-weight: 700;
			max-width: 980px;
			margin: 0 auto 0;
			padding: 30px 20px
		}
		&_logo {
			margin: 0px auto 0px;
		    height: 30vh;
		    display: flex;
		    align-items: center;
		    flex-direction: column;
		    justify-content: space-between;
			img {
		    	max-width: 200px;
				width: 100%;
			}
		}
	}
	&_information {
		font-size: 16px;
		font-weight: 300;
		text-transform: uppercase;
		color: #fff;
		padding: 20px;
		text-align: center;
	}
	@media only screen and (max-width: 768px) {
		.center__block {
			&_image {
				padding: 0px 20px;
				img {
				}
			}
			&_title {
				font-size: 28px;
				line-height: 36px;
				width: auto;
				padding: 20px 10px;
			}
			&_logo {
				img {
		    		max-width: 70px;
				}
			}
		}
		&_information {
			font-size: 8px;
			line-height: 11px;
			padding: 20px;
		}
	}
}