@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=PT+Sans&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');

html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #585858;
  user-select: none;
  sup {
    line-height: 0px;
    font-size: 50%;
  }
  .container {
    max-width: 980px;
    margin: 0px auto 0px;
    padding: 0px 20px;
  }
  .link_button {
    font-size: 22px;
    line-height: 26px;
    font-weight: 300;
    a {
      color: #E04F39;
      text-decoration: none;
    }
    @media only screen and (max-width: 768px) {
      font-size: 9px;
      line-height: 11px;
    }
  }
  button:not(.select-search__option) {
    max-width: 448px;
    width: 100%;
    height: 76px;
    background-color: #E04F39;
    border-radius: 50px;
    border: none;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    display: block;
    &:hover {
      background-color: #F9A05E;
    }
    &:active {
      border: 1px solid #E04F39;
      background-color: #fff;
      color: #E04F39;
    }
    &:disabled {
      border: 1px solid #B1B1B3;
      background-color: #fff;
      color: #B1B1B3;
      cursor: no-drop;
    }
    &.gray {
      background-color: #fff;
      border: 1px solid #474747;
      color: #585858;
      &:hover {
        border-color: #E04F39;
        color: #E04F39;
      }
    }
    @media only screen and (max-width: 768px) {
      height: 36px;
      font-size: 12px;
      line-height: 14px;
      max-width: 260px;
    }
  }
  .loadingApp {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #38518F;
    z-index: 99;
    background-image: url('../../assets/images/logo.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
}