.Calculator {
	background-color: #fff;
	height: 100%;
	&_form {
		height: 100%;
		.container {
			min-height: 100%;
			@media only screen and (max-width: 768px) {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
		.selectCustom {
			.css-tlfecz-indicatorContainer {
				svg {
					width: 50px;
					height: 50px;
				}
			}
		}
		form {
			padding: 100px 0;
			> div {
				padding: 0px 0px 100px;
				span {
					font-size: 24px;
					color: #38518F;
					margin-bottom: 15px;
					display: block;
				}
			}
			@media only screen and (max-width: 768px) {
				padding: 40px 0;
				> div {
					padding: 0px 0px 40px;
					span {
						font-size: 12px;
						line-height: 14px;
					}
				}
			}
		}
		button {
			margin: 0px auto 0px;
		}
	}
	&_warning {
		font-size: 24px;
		line-height: 28px;
		padding-left: 100px;
		background-image: url('../../assets/images/warning.svg');
		background-position: 0px center;
		background-repeat: no-repeat;
		min-height: 80px;
		display: flex;
		align-items: center;
		span {
			color: #E04F39;
		}
		&_children {
			font-family: 'Roboto', sans-serif;
			font-size: 22px;
			line-height: 26px;
			text-align: center;
			// font-variant: small-caps;
			color: #B1B1B3;
			font-weight: 300;
			margin: 50px 0;
		}
		@media only screen and (max-width: 768px) {
			font-size: 10px;
			line-height: 12px;
			background-size: 30px;
			padding-left: 40px;
			min-height: 30px;
			&_children {
				font-size: 9px;
				line-height: 11px;
				margin: 10px 0;
			}
		}
	}
	.footerNote {
		padding: 40px 0;
		@media only screen and (max-width: 768px) {
			padding: 20px 0;
		}
	}
	.disclaimer {
		margin: 70px 0 0;
		text-align: center;
		color: #B1B1B3;
		p {
			margin: 15px 0;
			u {
				text-transform: uppercase;
				text-decoration: none;
			}
			a {
				color: #B1B1B3;
			}
		}
		@media only screen and (max-width: 768px) {
			margin: 20px 0 0;
			p {
				font-size: 6.7px;
				margin: 10px 0;
			}
		}
	}
	.link_button {
		text-align: center;
	}
}

.Calculator_results {
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	top: 0px;
	display: block;
	border: none;
	width: auto;
	height: auto;
	text-align: center;
	overflow: auto;
	padding: 0;
	&_content {
		padding-bottom: 20px;
	}
	&_title {
		font-size: 36px;
		line-height: 43px;
		text-align: center;
		color: #38518F;
		font-weight: bold;
		margin-top: 80px;
		b {
			color: #E04F39;
		}
		&-sub {
			font-size: 28px;
			line-height: 32px;
			margin: 20px 0px 25px;
			font-weight: bold;
			color: #585858;
			b {
				color: #E04F39;
			}
		}
		&-doze {
			font-size: 28px;
			line-height: 32px;
			color: #B1B1B3;
		}
		@media only screen and (max-width: 768px) {
			font-size: 18px;
			line-height: 22px;
			margin-top: 20px;
			&-sub {
				font-size: 12px;
				line-height: 14px;
				margin: 10px 0px 15px;
			}
			&-doze {
				font-size: 12px;
				line-height: 14px;
			}
		}
	}
	button {
		margin: 45px auto 0px;
		@media only screen and (max-width: 768px) {
			margin: 20px auto 40px;
		}
	}
	.table {
		margin: 0px -7px 0 -7px;
	}
	table {
		width: 100%;
		border-collapse: initial;
		border-spacing: 7px;
		margin-top: 40px;
		tr {
			th {
				font-size: 28px;
				line-height: 33px;
				color: #585858;
				height: 90px;
				border-bottom: 1px solid rgba(187, 187, 187, 0.5);
				&:last-child {
					color: #38518F;
				}
			}
			td {
				font-size: 24px;
				line-height: 28px;
				color: #585858;
				height: 83px;
				border-bottom: 1px solid rgba(187, 187, 187, 0.5);
				padding-bottom: 7px;
				b {
					color: #38518F;
				}
				span {
					display: block;
					margin: 20px 0;
				}
				&:last-child {
					color: #38518F;
				}
			}
		}
		@media only screen and (max-width: 768px) {
			margin-top: 20px;
			tr {
				th {
					font-size: 12px;
					line-height: 14px;
					height: 30px;
				}
				td {
					font-size: 10px;
					line-height: 12px;
					height: 23px;
					span {
						display: block;
						margin: 10px 0;
					}
				}
			}
		}
	}
	.link_button {
		margin: 70px 0 40px;
		@media only screen and (max-width: 768px) {
			margin: 30px 0 30px;
		}
	}
	&_esklp {
		text-align: center;
		padding: 25px 0;
		@media only screen and (max-width: 768px) {
			padding: 0px 0;
		}
		&_title {
			font-size: 36px;
			line-height: 43px;
			text-align: center;
			color: #38518F;
			font-weight: bold;
			padding: 0px 30px;
			b {
				color: #E04F39;
			}
			&-sub {
				font-size: 28px;
				line-height: 32px;
				margin: 20px 0px 25px;
				font-weight: bold;
				b {
					color: #E04F39;
				}
			}
			&-doze {
				font-size: 28px;
				line-height: 32px;
				color: #B1B1B3;
			}
			@media only screen and (max-width: 768px) {
				font-size: 18px;
				line-height: 22px;
				padding-top: 15px;
				padding: 15px 10px 0;
				&-sub {
					font-size: 12px;
					line-height: 14px;
					margin: 10px 0px 15px;
				}
				&-doze {
					font-size: 12px;
					line-height: 14px;
				}
			}
		}
		.table {
			margin: 0 -7px;
		}
		table {
			width: 100%;
			border-collapse: initial;
			border-spacing: 7px;
			margin-top: 40px;
			tr {
				th {
					font-size: 28px;
					line-height: 33px;
					color: #585858;
					height: 90px;
					border-bottom: 1px solid rgba(187, 187, 187, 0.5);
					&:last-child {
						color: #38518F;
					}
				}
				td {
					font-size: 24px;
					line-height: 28px;
					color: #585858;
					height: 83px;
					padding-bottom: 7px;
					border-bottom: 1px solid rgba(187, 187, 187, 0.5);
					&:last-child {
						color: #38518F;
					}
				}
			}
			@media only screen and (max-width: 768px) {
				margin-top: 20px;
				tr {
					th {
						font-size: 12px;
						line-height: 14px;
						height: 30px;
					}
					td {
						font-size: 10px;
						line-height: 12px;
						height: 23px;
						padding-bottom: 7px;
					}
				}
			}
		}
		&_footnotes {
			text-align: left;
			font-size: 22px;
			line-height: 25px;
			font-weight: 300;
			color: #B1B1B3;
			margin-top: 100px;
			p {

			}
			@media only screen and (max-width: 768px) {
				font-size: 10px;
				line-height: 12px;
				margin-top: 20px;
				padding-bottom: 10px;
			}
		}
	}
}



/**
 * Main wrapper
 */
.select-search {
    width: auto;
    position: relative;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 20px);
    right: 40px;
    width: 25px;
    height: 25px;
    @media only screen and (max-width: 768px) {
    	top: calc(50% - 8px);
	    width: 10px;
	    height: 10px;
	    right: 20px;
    }
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 99px;
    width: 100%;
    padding: 15px 50px;
    background: #fff;
    border: 1px solid #B1B1B3;
    // box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    outline: none;
    font-size: 24px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    color: #585858;
    -webkit-appearance: none;
    @media only screen and (max-width: 768px) {
    	height: 40px;
    	padding: 5px 20px;
    	font-size: 12px;
    }
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    // border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 60px;
    width: 100%;
    padding: 15px 50px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 24px;
    text-align: left;
    cursor: pointer;
    color: #585858;
    @media only screen and (max-width: 768px) {
    	height: 40px;
    	padding: 5px 20px;
    	font-size: 12px;
    }
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: rgba(187, 187, 187, 0.14);
    color: #585858;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(187, 187, 187, 0.14);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: rgba(187, 187, 187, 0.14);
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #B1B1B3;
    border-bottom: 1px solid #B1B1B3;
    pointer-events: none;
    transform-origin: 50% 50%;
}

.select-search:not(.is-loading):not(.select-search--multiple).has-focus .select-search__value::after {
    transform: rotate(225deg);
    top: calc(50% - 10px);
    @media only screen and (max-width: 768px) {
    	top: calc(50% - 4px);
    }
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #38518F;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    left: 0;
    overflow: auto;
    max-height: 360px;
    margin: -5px 0 0 0;
    border-radius: 0 0 5px 5px;
    border: 1px solid #B1B1B3;
    border-top: none;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}