.Modal__warning {
	padding: 50px 20px 100px 20px;
	.warning {
		background: rgba(187, 187, 187, 0.07);
		border-radius: 5px;
		text-align: center;
		background-image: url('../../../assets/images/warning.svg');
		background-repeat: no-repeat;
		background-position: center 50px;
		padding: 170px 20px 50px 20px;
		max-width: 980px;
		margin: 0px auto 0px;
		font-size: 28px;
		color: #585858;
		font-weight: 700;
	}
	button {
		margin: 50px auto 0px;
	}
	@media only screen and (max-width: 768px) {
		padding: 10px 30px 90px 30px;
		.warning {
			background-position: center 10px;
			font-size: 12px;
			line-height: 16px;
			background-size: 30px;
			padding: 60px 10px 10px 10px;
		}
		button {
			margin: 10px auto 0px;
		}
	}
}
