@import-normalize;

.App, #root {
  height: 100%;
}

.App {
  .selectLang {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}