.Modal {
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	height: auto;
	background: rgba(89, 89, 89, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	&__close {
		position: absolute;
		right: 20px;
		top: 20px;
		display: block;
		cursor: pointer;
		img {
			width: 100%;
		}
	}
	&__content {
		background-color: #fff;
		border-radius: 10px 10px 0px 0px;
		width: 100%;
		position: relative;
		// overflow: auto;
		max-width: 1100px;
    	max-height: 100%;
		display: flex;
		flex-direction: column;
    	&:after {
    		content: '';
    		position: absolute;
    		left: 0px;
    		right: 0px;
    		top: 100%;
    		background-color: #fff;
    		height: 50px;
    	}
	}
	&__wrapper {
		max-height: 100%;
    	overflow: auto;
	}
	@media only screen and (max-width: 768px) {
		&__close {
			right: 10px;
			top: 10px;
			width: 15px;
			height: 15px;
		}
	}
}